// HACK (really stupid): hide the footer on md and above so it doesn't appear behind
// the content
.noFooter {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--body-bg);
}
