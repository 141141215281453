kbd {
  display: inline-block;
  width: 1.1rem;
  margin: 0 0.2rem -0.1rem 0.1rem;
  border-radius: 3px;
  font-weight: $font-weight-bold;
  text-align: center;
  vertical-align: text-bottom;
  color: var(--gray);
  background: var(--gray-lighter);
  box-shadow: 1px 1px 1px var(--gray);
}
