@import '~styles/utils/modules-entry.scss';

$timetable-border: 1px solid var(--gray-lighter);
$timetable-border-radius: 0.25em;

$timetable-right-padding: 2vw;
$timetable-day-h-size: 3.5rem;
$timetable-day-h-size-sm: 1.5rem;
$timetable-day-v-size: 2.5rem;

$timetable-row-height: 3.6rem;
$timetable-col-width: 4rem;

$timetable-min-width: $timetable-row-height * 16;
