@import "~styles/utils/modules-entry.scss";

$padding-h: 0.4rem;
$padding-v: 0.5rem;

.heading {
  position: sticky;
  top: 0;
  padding: $padding-v 0 $padding-v $padding-h;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid var(--gray-lighter);
  font-weight: $font-weight-bold;
  font-size: $font-size-lg;
  background: var(--body-bg);

  @include media-breakpoint-down(sm) {
    top: $navbar-height;
  }
}

.venueList {
  list-style: none;
  padding: 0;
}

.subList {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  list-style: none;
  padding: 0;
  margin-bottom: 0.5rem;

  li {
    flex: 1 1 7rem;
    margin: 1px 1px 0 0;
  }

  :global(.btn) {
    width: 100%;
    padding-right: $padding-h;
    padding-left: $padding-h;
    border-color: transparent;
    text-align: left;
  }
}

@supports (display: grid) {
  .subList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
    grid-gap: 1px;

    li {
      margin: 0;
    }
  }
}

.subtitle {
  font-size: $font-size-xs;
  color: var(--gray);
}

:global(.btn:hover) .subtitle {
  color: white;
}

:global(.btn-primary) .subtitle {
  color: white;
}
