@import '~styles/utils/modules-entry.scss';

/*
  For breakpoint-md (tablets), show the links as compact buttons with labels below icons.
  For breakpoint-lg (desktop), show the links as traditional link with icon to the left.
*/
.nav {
  position: fixed;
  top: $navbar-height;
  right: 0;
  left: 0;
  z-index: $navtabs-z-index;
  // See site.scss for width on md and lg sizes
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  height: $navtab-height;

  @include media-breakpoint-up(md) {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 0.5rem;
    box-shadow: none;
  }

  @include media-breakpoint-up(xl) {
    margin-top: 1rem;
  }

  @include media-breakpoint-down(sm) {
    position: sticky;
    top: 0;
    background-color: var(--body-bg);

    // Fake shadow - can't use box shadow because they will appear on top of the
    // top nav bar
    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: -$navtab-shadow-height;
      left: 0;
      height: $navtab-shadow-height;
      background: linear-gradient(to bottom, var(--navtab-shadow), rgba(#000, 0));
      pointer-events: none;
    }
  }
}

.link {
  position: relative;
  flex: 1 0 auto;
  line-height: 1.6;
  text-align: center;
  color: var(--gray-mid);
  transition: color 0.15s;

  &.linkActive {
    color: theme-color(primary);
  }

  &:hover .title {
    text-decoration: underline;
  }

  @include media-breakpoint-down(sm) {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-bottom: 0.1rem;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 0.8rem 0.5rem;
  }

  @include media-breakpoint-up(xl) {
    display: flex;
    align-items: center;
    padding: 0.8rem 0 0.8rem 2rem;
    text-align: left;
  }
}

.title {
  display: none;
  font-size: 0.7rem;

  @include media-breakpoint-up(md) {
    display: block;
  }

  @include media-breakpoint-up(xl) {
    margin-left: 0.4rem;
    font-size: 1rem;
  }
}

.hiddenOnMobile {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.divider {
  $size: 70%;
  $margin: 0.3rem;

  composes: hiddenOnMobile;
  opacity: 0.6;
  flex: 0 0 1px;
  margin: $margin ((100% - $size) * 0.5) 0;
  background: var(--gray-lighter);

  @include media-breakpoint-up(xl) {
    margin: $margin 1rem $margin 1.4rem;
  }
}

.updateDot {
  position: absolute;
  top: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  padding: 0;
  margin-left: 1rem;
  border-radius: 50%;
  font-size: $font-size-xs;
  line-height: 1.2;
  background: theme-color(success);

  :global {
    animation: zoomIn 0.4s;
  }

  @include media-breakpoint-up(md) {
    right: 1.4rem;
  }

  @include media-breakpoint-up(xl) {
    display: none;
  }
}
