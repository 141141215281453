// Layout

// Also update the NAVTAB_HEIGHT constant in Navtabs.jsx if these are changed
$navbar-height: 3rem;
$navtab-height: 3rem;

$side-nav-width-md: 4rem;
$side-nav-width-lg: 10rem;

$navtab-shadow-height: 0.8rem;
$navtab-shadow-color: rgba(#000, 0.1);
$navtab-shadow-color-night: rgba(#000, 0.7);

// Type
$sm-font-size-ratio: 0.875;

// Animations
$fullscreen-duration: 0.375s;
$exiting-duration: 0.195s;
$entering-duration: 0.225s;
$desktop-fullscreen-duration: 0.275s;
$desktop-exiting-duration: 0.175s;
$desktop-entering-duration: 0.195s;
$material-standard-curve: cubic-bezier(0.4, 0, 0.2, 1);
$material-deceleration-curve: cubic-bezier(0, 0, 0.2, 1);
$material-acceleration-curve: cubic-bezier(0.4, 0, 1, 1);
$material-sharp-curve: cubic-bezier(0.4, 0, 0.6, 1);

$page-entering-duration: $fullscreen-duration;
/* stylelint-disable-next-line value-keyword-case */
$page-entering-animation: fadeIn;

$leaflet-control-offset: 0.5rem;

$alert-override-themes: success, info, danger, warning;

// Workload colors map
$workload-colors: (
  lecture: #bbdefb,
  tutorial: #dcedc8,
  laboratory: #d7ccc8,
  project: #ffe0b2,
  preparation: #ffcdd2,
);

// Theme colors. Have to correspond with js/data/themes.json
$nusmods-theme-colors: (
  ashes: (
    #c7ae95,
    #c7c795,
    #aec795,
    #95c7ae,
    #95aec7,
    #ae95c7,
    #c795ae,
    #c79595
  ),
  chalk: (
    #fb9fb1,
    #eda987,
    #ddb26f,
    #acc267,
    #12cfc0,
    #6fc2ef,
    #e1a3ee,
    #deaf8f
  ),
  eighties: (
    #f2777a,
    #f99157,
    #fc6,
    #9c9,
    #6cc,
    #69c,
    #c9c,
    #d27b53
  ),
  google: (
    #cc342b,
    #f96a38,
    #fba922,
    #198844,
    #3971ed,
    #79a4f9,
    #a36ac7,
    #ec9998
  ),
  mocha: (
    #cb6077,
    #d28b71,
    #f4bc87,
    #beb55b,
    #7bbda4,
    #8ab3b5,
    #a89bb9,
    #bb9584
  ),
  monokai: (
    #f92672,
    #fd971f,
    #f4bf75,
    #a6e22e,
    #a1efe4,
    #66d9ef,
    #ae81ff,
    #c63
  ),
  ocean: (
    #bf616a,
    #d08770,
    #ebcb8b,
    #a3be8c,
    #96b5b4,
    #8fa1b3,
    #b48ead,
    #ab7967
  ),
  oceanic-next: (
    #ec5f67,
    #f99157,
    #fac863,
    #99c794,
    #5fb3b3,
    #69c,
    #c594c5,
    #ab7967
  ),
  paraiso: (
    #ef6155,
    #f99b15,
    #fec418,
    #48b685,
    #5bc4bf,
    #06b6ef,
    #815ba4,
    #e96ba8
  ),
  railscasts: (
    #da4939,
    #cc7833,
    #ffc66d,
    #a5c261,
    #519f50,
    #6d9cbe,
    #b6b3eb,
    #bc9458
  ),
  tomorrow: (
    #c66,
    #de935f,
    #f0c674,
    #b5bd68,
    #8abeb7,
    #81a2be,
    #b294bb,
    #a3685a
  ),
  twilight: (
    #cf6a4c,
    #cda869,
    #f9ee98,
    #8f9d6a,
    #afc4db,
    #7587a6,
    #9b859d,
    #9b703f
  ),
);

// Z-index
$browser-warning-z-index: 9999;
$snackbar-z-index: 2500;
$sentry-z-index: 2000;
$modal-z-index: 1500;
$module-select-z-index: 960;
$module-finder-search-z-index-sm: 950;
$venue-detail-expanded-z-index: 920;
$navtabs-z-index: 900;
$navbar-z-index: 890;
$module-finder-search-z-index-md: 850;

// navtabs and navbar should never overlap the dropdown
$modreg-notification-z-index: 970;
$zindex-dropdown: 910; // Bootstrap override
$fab-z-index: 800;
$venue-map-z-index: 760;
$venue-detail-z-index: 750;
$side-menu-z-index: 700;
$side-menu-overlay-z-index: 690;
$timetable-warning-z-index: 200;
$timetable-scolled-day-z-index: 120;
$timetable-selected-cell-z-index: 110;
$timetable-timing-z-index: 95;
$timetable-day-z-index: 90;
$timetable-current-time-indicator-z-index: 60;
$timetable-cell-z-index: 30;
