@import '~styles/utils/modules-entry.scss';

.footer {
  composes: text-muted from global;
  padding: 4rem 0 4rem 0; // For Microsoft Edge 18
  padding: 4rem env(safe-area-inset-right) calc(env(safe-area-inset-bottom) + 4rem) env(safe-area-inset-left);
  margin-top: 4rem;
  font-size: 0.85rem;
  text-align: center;
  background-color: var(--gray-lightest);

  a,
  button {
    font-weight: 500;
    color: var(--gray);

    &:hover {
      color: $link-color;
    }
  }

  p {
    margin-bottom: 0.4rem;
    line-height: 1.3;
  }

  .footerContainer {
    padding: 0 15px;
  }

  @include media-breakpoint-up(sm) {
    text-align: left;
  }

  @include media-breakpoint-up(md) {
    padding-left: #{$side-nav-width-md}; // For Microsoft Edge 18
    padding-left: calc(env(safe-area-inset-left) + #{$side-nav-width-md});
  }

  @include media-breakpoint-up(xl) {
    padding-left: #{$side-nav-width-lg}; // For Microsoft Edge 18
    padding-left: calc(env(safe-area-inset-left) + #{$side-nav-width-lg});
  }
}

.footerLinks {
  padding-left: 0;
  margin-bottom: 1rem;

  li {
    display: inline-block;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .feedbackBtn {
    font-weight: bold;
  }
}
