@import '~styles/utils/modules-entry.scss';

$gutter: $grid-gutter-width * 0.5;
$sticky-top: $navbar-height + 1rem;
$venue-list-width: 16rem;

// Layout for venues page is
// - On md and above - Outlook style two panel UI with the venue list on the left
//   and venue detail on the right
// - On sm and below - Single column venue list, with the venue detail appearing
//   in a modal when venue is selected
.pageContainer {
  :global {
    animation-name: $page-entering-animation;
  }
}

.venueDetail {
  &.mapExpanded {
    // Venue detail need to appear above everything else, including
    // page navigation when the map is expanded
    z-index: $venue-detail-expanded-z-index;
  }

  &:not(.mapExpanded) {
    // Only include the scrollable mixin when the map is not expanded
    // to prevent the map from being clipped in Safari Mobile
    @include scrollable-y;
  }
}

@include media-breakpoint-up(md) {
  .venuesList,
  .venueDetail {
    position: fixed;
    top: $sticky-top;
    bottom: 0;
    z-index: $venue-detail-z-index;
  }

  .venuesList {
    width: $venue-list-width;
    padding-bottom: 0; // For Microsoft Edge 18
    padding-bottom: env(safe-area-inset-bottom);
    border-right: 1px solid var(--gray-lighter);

    @include scrollable-y;
  }

  .venueDetail {
    right: 0; // For Microsoft Edge 18
    right: env(safe-area-inset-right);
    left: calc(#{$venue-list-width + $side-nav-width-md} + #{$gutter}); // For Microsoft Edge 18
    left: calc(env(safe-area-inset-left) + #{$venue-list-width + $side-nav-width-md} + #{$gutter});
    padding: 0 $gutter 0 $gutter; // For Microsoft Edge 18
    padding: 0 $gutter env(safe-area-inset-bottom) $gutter;
  }
}

@include media-breakpoint-up(xl) {
  .venueDetail {
    left: calc(#{$venue-list-width + $side-nav-width-lg} + #{$gutter});
  }
}

@media (min-width: 90rem) {
  .venuesList {
    right: 60rem;
    left: $side-nav-width-lg;
    width: auto;
  }

  .venueDetail {
    left: auto;
    width: 60rem;
  }
}

.venueDetailModal {
  padding: 0;

  .closeButton {
    padding: 0.5rem 0;
    margin-bottom: 1rem;
    border-width: 0 0 1px;
    border-radius: 0;
  }
}

.venueSearch {
  margin: 0 0.5rem 1rem 0;

  h3 {
    margin-bottom: 0;
    font-weight: $font-weight-bold;
    font-size: $font-size-lg;
  }

  @include media-breakpoint-down(sm) {
    margin-right: 0;
  }
}

.searchBox {
  margin-bottom: 1rem;

  @include media-breakpoint-down(sm) {
    border-bottom: 1px solid var(--gray-light);
  }
}

.availabilitySearch {
  padding: ($border-radius + 0.2rem) 1rem 1rem;
  margin-top: -$border-radius;
  border: 1px solid theme-color();
  border-width: 0 1px 1px;
  border-radius: 0 0 $border-radius $border-radius;
}

.noVenueSelected {
  $icon-size: 6rem;

  opacity: 0.6;
  margin-top: 10vh;
  font-size: 1.4rem;
  text-align: center;

  svg {
    width: $icon-size;
    height: $icon-size;
    margin-bottom: 1rem;
  }
}
