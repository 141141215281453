@import '~styles/utils/modules-entry';

.pageContainer {
  composes: page-container from global;
  max-width: 45rem;
  height: 100%;

  :global {
    animation-name: $page-entering-animation;
  }
}

.header {
  padding: 0 0 1rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid var(--gray-lighter);

  h1 {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  h4 {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
}

.subtitle {
  padding-top: 1rem;
  font-weight: bold;
}
