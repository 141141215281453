.semesterSwitcher {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

.semesterName {
  min-width: 130px;
  margin: 0 0.5rem;
  text-align: center;
}
