@import '~styles/utils/modules-entry';

$theme-selected-bg-color: var(--gray-lighter);
$theme-hover-bg-color: var(--gray-lightest);
$theme-option-gap: 0.7rem;

.option {
  padding: $theme-option-gap;
  border-width: 0;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color $desktop-entering-duration $material-deceleration-curve;

  &:hover {
    background-color: $theme-hover-bg-color;
  }

  &:focus {
    outline: 0;
  }

  &.isSelected {
    background-color: $theme-selected-bg-color;
  }
}

.colorList {
  $number-colors: 8;

  .colorItem {
    $size: 12.5%; // 100% / $number-colors

    display: inline-block;
    width: $size;
    padding-bottom: $size;
  }
}
