@import '~styles/utils/modules-entry.scss';

.delete {
  align-self: center;
  width: fit-content;
  height: fit-content;
  padding: 0.3rem;
  margin-right: 1rem;
  border: 1px solid var(--gray-lighter);
  border-radius: 0.35rem;
  font-weight: bold;
  color: 1px solid var(--gray-lighter);
  background: var(--body-bg);
}

.x {
  opacity: 0.6;
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 0.04rem;
}

@include media-breakpoint-down(sm) {
  .delete {
    align-self: unset;
    margin: -1rem -0.7rem 0 0;
  }
}
