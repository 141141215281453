@import '~styles/utils/modules-entry.scss';

.modulesTable {
  font-size: 0.9rem;
}

.modulesTableRow {
  display: flex;
  margin-bottom: 0.7rem;
}

.moduleColor {
  margin-top: 0.1rem;
  margin-right: 0.5rem;
}

.moduleInfo {
  display: table-cell;
  flex: 1 1 auto;
}

.moduleInfo.tombstone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 0.3rem 0.3rem 0.9rem;
  border-radius: 3px;
  background: var(--gray-lightest);

  .moduleActionButtons {
    flex: 1 0 auto;
    text-align: right;
  }

  .moduleAction {
    flex: 0 1 auto;
    text-transform: uppercase;
  }
}

.moduleExam {
  display: block;
  font-size: 87%;
}

.moduleAction {
  > .actionIcon {
    margin: 0;
  }
}

.actionIcon {
  composes: svg-small from global;
}

.moduleActionButtons {
  float: right;
  margin-left: 0.2rem;

  @include media-breakpoint-up(md) {
    .moduleAction {
      padding: 0.5rem;
      border: 0;
    }
  }
}

.footer {
  font-size: $font-size-sm;
  color: var(--gray-light);

  hr {
    margin: 1rem 0 0.5rem;
  }

  strong {
    color: var(--gray);
  }
}

.moduleOrder {
  text-align: right;

  label {
    padding-right: 1rem;
    margin: 0;
  }

  :global(.form-control) {
    display: inline-block;
    width: auto;
    text-align: left;
  }
}
