.svg-small {
  $size: 1.1rem;

  width: $size;
}

.btn-svg {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  > .svg {
    flex: 0 0 auto;
    margin-right: 0.2rem;
  }
}

.btn-inline {
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  vertical-align: baseline;

  @extend .btn-link;
}
