@import '~styles/utils/modules-entry.scss';

.searchWrapper {
  position: relative;

  input {
    padding: 0.8rem 2rem 0.8rem 2rem;
    border-width: 0 0 1px;
    border-radius: 0;
    &:focus {
      box-shadow: none;
    }
  }

  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  .removeInput {
    position: absolute;
    right: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    transition: color 0.3s;
  }

  .removeInput:hover {
    color: theme-color();
  }
}

.leftAccessory {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: color 0.3s;

  .spinner {
    display: block;
  }
}

.searchBoxFocused {
  .leftAccessory {
    color: theme-color();
  }
}

.searchHelp {
  position: absolute;
  top: calc(50% - 0.9rem);
  right: 0.5rem;
  font-size: 0.9rem;
  color: var(--gray-light);
}

@include media-breakpoint-down(sm) {
  .searchWrapper {
    input {
      padding: 0.8rem 1.6rem 0.8rem 2.2rem;
      border: 0;
      font-size: 1rem;
    }

    input::placeholder {
      // For placeholder text to fully show on 320px viewports
      overflow: visible;
    }
  }

  .leftAccessory {
    left: $grid-gutter-width * 0.5;

    &.searchIcon {
      width: 1rem;
      padding-bottom: 0.1rem; // Visual alignment
    }

    &.spinnerContainer {
      padding-bottom: 0.2rem; // Visual alignment
    }
  }

  .removeInput {
    width: 1.4rem;
    padding-right: 0.2rem;
  }
}

@include mobile-landscape {
  .searchBox {
    top: 0;
  }
}
